import { api } from "../../../services/api";

export async function fetchFilterHome(imoveisIds) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@jarvis:token");
    api
      .post(
        "/dev/homefilter",
        {
          ids: imoveisIds,
          token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
