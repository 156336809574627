import React, { useState } from "react";
import { fetchAlterarSenha } from "../../integration/alterarsenha/fetchAlterarSenha";
import { formatToPhone } from "brazilian-values";

const MeuPerfil = () => {
  const [error, setError] = useState("");
  const [sucess, setSucess] = useState("");

  const [novasenha, setNovasenha] = useState("");
  const [repetirsenha, setRepetirsenha] = useState("");

  const handleValorChange = (e) => {
    setNovasenha(e.target.value);
  };

  const handleWalletChange = (e) => {
    setRepetirsenha(e.target.value);
  };

  async function funcAlterarSenha() {
    try {
      const resp = await fetchAlterarSenha(novasenha, repetirsenha);
      setError("");
      setSucess(resp);
    } catch (erro) {
      setSucess("");
      setError(erro);
    }
  }

  let nomecliente = localStorage.getItem("@jarvis:nome") || "";
  let telefone = localStorage.getItem("@jarvis:telefone") || "";
  let emailcliente = localStorage.getItem("@jarvis:email") || "";
  let localidade = localStorage.getItem("@jarvis:localidade") || "";

  return (
    <div className="p-4 bg-gray-100 rounded shadow-lg">
      {error && (
        <div className="text-center my-5 bg-red-500 border border-slate-100 rounded p-2">
          <div className="text-[13px]  text-slate-100">{error}</div>
        </div>
      )}

      {sucess && (
        <div className="text-center my-5 bg-green-400 border border-slate-100 rounded p-2">
          <div className="text-[13px]  text-slate-100">{sucess}</div>
        </div>
      )}
      {!sucess && (
        <div className="text-slate-500">
          <>
            <h2 className="  text-[15px] font-bold uppercase mb-2">
              Dados da conta
            </h2>
          </>

          <div className="mb-4 text-[15px] text-slate-500">
            <label htmlFor="valorInvestido" className=" mb-2 flex">
              Nome Completo: <p className="font-bold  ml-2 "> {nomecliente}</p>
            </label>

            <label htmlFor="valorInvestido" className=" mb-2 flex">
              E-mail: <p className="font-bold  ml-2 ">{emailcliente}</p>
            </label>
            <label htmlFor="valorInvestido" className=" mb-2 flex">
              Telefone:{" "}
              <p className="font-bold  ml-2 ">{formatToPhone(telefone)}</p>
            </label>
            <label htmlFor="valorInvestido" className=" mb-2 flex">
              Localidade: <p className="font-bold  ml-2 ">{localidade}</p>
            </label>
          </div>

          <>
            <h2 className="  text-[15px] font-bold uppercase">Alterar Dados</h2>
            <h2 className="  text-[15px]  mb-4">
              Insira as informações abaixo para alterar a senha.
            </h2>
          </>

          <div className="mb-4 text-[15px]">
            <label htmlFor="valorInvestido" className="block mb-2">
              Nova Senha:
            </label>
            <input
              type="text"
              className="border p-2 rounded-md w-full"
              value={novasenha}
              onChange={handleValorChange}
              placeholder="Nova Senha"
            />
            <label htmlFor="valorInvestido" className="block mt-2 mb-2">
              Repetir Senha:
            </label>
            <input
              type="text"
              className="border p-2 rounded-md w-full"
              value={repetirsenha}
              onChange={handleWalletChange}
              placeholder="Repetir Senha"
            />
          </div>

          <button
            onClick={funcAlterarSenha}
            className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] mt-4 py-2 rounded-2xl text-slate-100 mb-2 flex items-center justify-center"
          >
            Alterar Senha
          </button>
        </div>
      )}
    </div>
  );
};

export default MeuPerfil;
