import React, { useState, useEffect } from "react";
import {
  BiDollar,
  BiBuilding,
  BiFile,
  BiCalculator,
  BiCopy
} from "react-icons/bi";
import { FaShareAlt } from "react-icons/fa"; // Importando o ícone de Google Maps
import { AiOutlineLoading } from "react-icons/ai";
import { formatToBRL } from "brazilian-values";
import { BsPlusLg } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { fetchListaFavorito } from "../../integration/lista_favoritos/fetchListaFavorito";
import { fetchDescricaoFavoritos } from "../../integration/descricao_favoritos/fetchDescricaoFavoritos";
import CardCalculadora from "../../components/calculadora/index";
import Modal from "../../components/Modal/Modal"; // Importe o componente Modal

import "react-toastify/dist/ReactToastify.css";

const PropertyDetails = ({ tipovisao, versao = "desktop" }) => {
  let tipocoluna = 2;

  if (versao === "mobile") {
    tipocoluna = 1;
  }

  let tipocoluna2 = 2;

  if (versao === "mobile") {
    tipocoluna2 = 1;
  }

  useEffect(() => {
    funcFilterHome();
  }, []); // Executa apenas na montagem do componente

  if (tipovisao === "mobile") {
    tipocoluna = 1;
  }

  const [error, setError] = useState("");
  const [success, setSuccess] = useState([]);
  const [copyMessage, setCopyMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Estado para a página atual
  const itemsPerPage = 4; // Número de itens por página
  const [loading, setLoading] = useState(true); // Estado para controle de carregamento
  const [msgErro, setMsgErro] = useState(false); // Estado para controle de carregamento
  const [propertyDescriptions, setPropertyDescriptions] = useState({}); // Armazena as descrições de cada imóvel

  useEffect(() => {
    // Rolar para o topo quando a página mudar
    window.scrollTo(0, 0);
  }, [currentPage]);

  const handleCopy = (text) => {
    if (text) {
      navigator.clipboard.writeText(text).then(
        () => {
          setCopyMessage("Texto copiado!");
          setTimeout(() => setCopyMessage(""), 2000);
        },
        () => {
          setCopyMessage("Falha ao copiar texto.");
          setTimeout(() => setCopyMessage(""), 2000);
        }
      );
    } else {
      setCopyMessage("Texto não disponível para cópia.");
      setTimeout(() => setCopyMessage(""), 2000);
    }
  };

  async function funcFilterHome() {
    setLoading(true); // Começar o carregamento
    try {
      const resp = await fetchListaFavorito();

      setError("");
      setSuccess(resp);
      setMsgErro(false);

      // Inicializa as descrições dos imóveis
      const initialDescriptions = resp.reduce((acc, property) => {
        acc[property.bas_id] = property.fav_descricao || ""; // Define a descrição inicial
        return acc;
      }, {});
      setPropertyDescriptions(initialDescriptions);
    } catch (erro) {
      setMsgErro(true);
      setSuccess([]);
      setError(erro.message);
    } finally {
      setLoading(false); // Finalizar o carregamento
    }
  }

  const handleDescriptionChange = (propertyId, e) => {
    const newValue = e.target.value;

    setPropertyDescriptions((prev) => ({
      ...prev,
      [propertyId]: newValue // Atualiza a descrição para o imóvel específico
    }));
  };

  // Função para salvar a descrição do imóvel
  const handleSaveDescription = async (propertyId) => {
    let id_base = propertyId.bas_id;

    let excluir = false;
    let descricao = propertyDescriptions[id_base]; // Convertendo para string

    try {
      await fetchDescricaoFavoritos(excluir, propertyId, descricao);
      toast.success(`Descrição salva com sucesso!`);
    } catch (erro) {
      toast.error("Por favor, insira uma descrição.");
    }
  };

  // Função para salvar a descrição do imóvel
  const handleExcluirDescription = async (propertyId) => {
    let id_base = propertyId.bas_id;

    let excluir = true;
    let descricao = propertyDescriptions[id_base]; // Convertendo para string
    setLoading(true);
    try {
      await fetchDescricaoFavoritos(excluir, propertyId, descricao);

      window.location.reload();
    } catch (erro) {
      toast.error("Por favor, insira uma descrição.");
    }
  };

  // Calcular os índices de início e fim para a página atual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = success.slice(indexOfFirstItem, indexOfLastItem);

  // Função para mudar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Total de páginas
  const totalPages = Math.ceil(success.length / itemsPerPage);

  const [notification, setNotification] = useState("");

  const handleShareClick = (bas_numero_imovel) => {
    const link = `https://venda-imoveis.caixa.gov.br/sistema/detalhe-imovel.asp?hdnOrigem=index&hdnimovel=${bas_numero_imovel.replace(
      /Número do imóvel: |-/g,
      ""
    )}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copiado para a área de transferência!");
      })
      .catch(() => {
        toast.error("Erro ao copiar o link.");
      });
  };

  const [dadosCalculadora, setDadosCalculadora] = useState(null);
  const [isscalculator, setIscalculator] = useState(false);

  const handleClickCalculator = (property) => {
    // Envia os dados da propriedade para o CardCalculadora
    setDadosCalculadora(property);
    setIscalculator(true); // Isso abre o modal
  };

  const handleFecharCalculator = () => {
    setIscalculator(false);
    setDadosCalculadora(null);
  };

  return (
    <>
      <ToastContainer /> {/* Container para exibir notificações */}
      {loading ? (
        <div className="flex justify-center items-center w-full bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]">
          <AiOutlineLoading className="animate-spin text-[#dda219] text-4xl" />
        </div>
      ) : (
        <>
          {/* Modal para a Calculadora */}
          {isscalculator && (
            <Modal onClose={handleFecharCalculator}>
              <CardCalculadora
                property={dadosCalculadora}
                tipovisao={versao}
                acessoucel={tipocoluna2}
              />
              <button
                className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] mt-4 py-2 rounded-2xl text-slate-100"
                onClick={handleFecharCalculator}
              >
                Fechar Calculadora
              </button>
            </Modal>
          )}

          <div
            className={`grid grid-cols-${tipocoluna} p-2 gap-2 w-full bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]`}
          >
            {currentItems.map((property) => (
              <div
                key={property.bas_id}
                className="w-full bg-white border border-gray-300 shadow-md rounded-lg flex flex-col overflow-hidden"
              >
                <div className="flex flex-col h-full p-6">
                  <div className="grid grid-cols-1 p-1 mb-2 rounded-md bg-gray-200">
                    <p className="text-gray-600 font-semibold text-[15px]">
                      {property.bas_title}
                    </p>
                  </div>

                  <div className="flex-grow flex items-center justify-center mb-4">
                    <img
                      className="w-full h-40 object-cover rounded-md border border-gray-200"
                      src={property.bas_image}
                      alt={property.bas_title}
                    />
                  </div>

                  {notification && (
                    <div className="notification bg-green-100 p-2 my-5 rounded">
                      {notification}
                    </div>
                  )}
                  <div className="flex flex-col flex-grow">
                    <div className="flex justify-between items-center mb-2">
                      <h1 className="font-extrabold text-gray-700 text-[15px]">
                        {property.bas_title}
                      </h1>
                      {property.bas_desconto && (
                        <p className="text-red-500 font-bold text-[13px] bg-red-100 px-2 py-1 rounded-md">
                          {(property.bas_desconto / 100)
                            .toFixed(2)
                            .replace(".", ",")}
                          %
                        </p>
                      )}
                    </div>
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center mb-2 text-gray-600 text-[15px]">
                        <BiBuilding className="text-yellow-700 mr-2" />
                        <p>
                          <span className="font-semibold">Tipo de imóvel:</span>{" "}
                          {property.bas_tipo}
                        </p>
                      </div>
                      <div>
                        <div className="flex">
                          <FaShareAlt
                            className="text-black cursor-pointer"
                            onClick={() =>
                              handleShareClick(property.bas_numero_imovel)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <hr className="border-t border-gray-300 my-2" />
                    <div className="flex justify-between items-center mb-2 text-gray-600 text-[15px]">
                      <span className="flex items-center">
                        <BiDollar className="text-yellow-700 mr-2" />
                        Valor de avaliação:
                      </span>
                      <p className="line-through text-gray-500 text-[12px]">
                        {formatToBRL(property.bas_valor_avaliacao) ||
                          "Não disponível"}
                      </p>
                    </div>
                    <div className="flex justify-between items-center mb-2 text-gray-600 text-[15px]">
                      <span className="flex items-center">
                        <BiDollar className="text-yellow-700 mr-2" />
                        Valor mínimo de venda:
                      </span>
                      <div className="flex items-center">
                        <p className="font-bold text-green-600 text-[12px]">
                          {formatToBRL(property.bas_valor_minimo_venda) ||
                            "Não disponível"}
                        </p>
                        {/* Adiciona o onClick no ícone da calculadora */}
                        <BiCalculator
                          className="text-gray-500 ml-2 text-[15px] cursor-pointer"
                          onClick={() => handleClickCalculator(property)} // Função passada corretamente
                        />
                      </div>
                    </div>

                    <div>
                      <div className="flex justify-between items-center mb-2 text-gray-600 text-[15px]">
                        <span className="flex items-center">
                          <BiDollar className="text-yellow-700 mr-2" />
                          Valor mínimo de venda:
                        </span>
                      </div>
                    </div>

                    <hr className="border-t border-gray-300 my-2" />

                    <div className="flex items-center  text-gray-600 text-[15px]">
                      <BiFile className="text-yellow-700 mr-2" />
                      <p>
                        <span className="font-semibold">Número do imóvel:</span>{" "}
                        {property.bas_numero_imovel
                          ? property.bas_numero_imovel.replace(
                              "Número do imóvel: ",
                              ""
                            )
                          : "Número não disponível"}
                      </p>
                      <button
                        onClick={() =>
                          handleCopy(property.bas_numero_imovel || "")
                        }
                        className="ml-2 p-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                      >
                        <BiCopy />
                      </button>
                    </div>
                    {copyMessage && (
                      <p className="text-green-500">{copyMessage}</p>
                    )}

                    <hr className="border-t border-gray-300 my-2" />

                    <div className="flex flex-col">
                      <label className="mb-1 font-semibold text-slate-600 text-[15px]">
                        Descrição:
                      </label>
                      <textarea
                        className="bg-[#f7f7c1] text-slate-600 border border-gray-300 rounded-md p-2 mb-2"
                        value={propertyDescriptions[property.bas_id] || ""}
                        onChange={(e) =>
                          handleDescriptionChange(property.bas_id, e)
                        }
                        rows="3"
                        placeholder="Insira a descrição aqui..."
                      />
                      <button
                        className="  text-white py-1 px-2 rounded-md w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#5cbd81] to-[#5cbd81]"
                        onClick={() =>
                          handleSaveDescription({
                            fav_id: property.fav_id,
                            bas_id: property.bas_id
                          })
                        }
                      >
                        Salvar Descrição
                      </button>

                      <button
                        className="  text-white py-1 px-2 rounded-md w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#d65d5d] to-[#d65d5d]"
                        onClick={() =>
                          handleExcluirDescription({
                            fav_id: property.fav_id,
                            bas_id: property.bas_id
                          })
                        }
                      >
                        Remover dos favoritos
                      </button>

                      <button
                        type="submit"
                        className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] mt-4 py-2 rounded-2xl text-slate-100 mb-2 flex items-center justify-center"
                        onClick={() =>
                          window.open(
                            `https://venda-imoveis.caixa.gov.br/sistema/detalhe-imovel.asp?hdnOrigem=index&hdnimovel=${property.bas_numero_imovel.replace(
                              /Número do imóvel: |-/g,
                              ""
                            )}`,
                            "_blank"
                          )
                        }
                      >
                        <BsPlusLg className="font-bold" />
                        Detalhes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {success.length > 0 && (
            <div className="flex items-center justify-center  mt-8 my-10">
              <div className="flex items-center justify-between w-full text-gray-600 dark:text-gray-400 bg-gray-100 rounded-lg dark:bg-gray-600 max-w-[128px] mx-2">
                <button
                  type="button"
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="inline-flex items-center justify-center h-8 px-1 w-6 bg-gray-100 rounded-s-lg dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-800"
                >
                  <svg
                    className="w-2 h-2 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 1 1 5l4 4"
                    />
                  </svg>
                  <span className="sr-only">Página anterior</span>
                </button>
                <span className="flex-shrink-0 mx-1 text-sm font-medium space-x-0.5 rtl:space-x-reverse">
                  {currentPage} de {totalPages}
                </span>

                <button
                  type="button"
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="inline-flex items-center justify-center h-8 px-1 w-6 bg-gray-100 rounded-e-lg dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-800"
                >
                  <svg
                    className="w-2 h-2 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <span className="sr-only">Próxima página</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PropertyDetails;
