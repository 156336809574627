import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BiHomeSmile,
  BiLogOut,
  BiUser,
  BiSearchAlt,
  BiBookHeart,
  BiCctv,
  BiTachometer,
  BiHomeHeart,
} from "react-icons/bi";
import { FaCalculator } from "react-icons/fa";

import Logo from "../../assets/logo/logo.png";

const Sidebar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [isMenuSair, setIsMenuSair] = useState(true);
  const location = useLocation();

  const handleMenuClickDisconect = () => {
    localStorage.clear();
    window.location.reload();
  };

  function getLinkClass(paths) {
    return paths.some((path) => location.pathname.includes(path))
      ? "bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] text-slate-200"
      : "text-[#85480e]";
  }

  function getLinkClassManutencao(paths) {
    return paths.some((path) => location.pathname.includes(path))
      ? "bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] text-slate-200"
      : "text-slate-400";
  }

  return (
    <aside className="text-gray-300 w-full md:w-64 flex-shrink-0 ">
      <div className="fixed top-0 left-0 bottom-0 w-64  shadow-lg overflow-y-auto">
        <div className="flex mt-10 items-center justify-center mb-8">
          <img className="h-20" src={Logo} alt="Logo" />
        </div>
        <nav className={`${isOpen ? "block" : "hidden md:block"}`}>
          <ul className="space-y-2">
            <hr className="border-2 border-t border-[#dcdcdc]" />

            <li>
              <a
                href="/dashboard"
                className={`flex items-center py-2 px-4 ${getLinkClass([
                  "/dashboard",
                ])}`}
              >
                <BiTachometer className="ml-5" size={20} />
                <span className="ml-2">Página Inicial</span>
              </a>
            </li>
            <hr className="border-2 border-t border-[#dcdcdc]" />

            <li>
              <a
                href="/perfil"
                className={`flex items-center py-2 px-4 ${getLinkClass([
                  "/perfil",
                ])}`}
              >
                <BiUser className="ml-5" size={20} />
                <span className="ml-2">Meu Perfil</span>
              </a>
            </li>

            <hr className="border-2 border-t border-[#dcdcdc]" />

            <li>
              <a
                href="/busca"
                className={`flex items-center py-2 px-4 ${getLinkClass([
                  "/busca",
                ])}`}
              >
                <BiSearchAlt className="ml-5" size={20} />
                <span className="ml-2">Busca Avançada</span>
              </a>
            </li>

            <hr className="border-2 border-t border-[#dcdcdc]" />

            <li>
              <a
                href="/favoritos"
                className={`flex items-center py-2 px-4 ${getLinkClass([
                  "/favoritos",
                ])}`}
              >
                <BiBookHeart className="ml-5" size={20} />
                <span className="ml-2">Favoritos</span>
              </a>
            </li>

            <hr className="border-2 border-t border-[#dcdcdc]" />

            <li>
              <a
                href="/calculadora"
                className={`flex items-center py-2 px-4 ${getLinkClass([
                  "/calculadora",
                ])}`}
              >
                <FaCalculator className="ml-5" size={20} />
                <span className="ml-2">Calculadora</span>
              </a>
            </li>

            <hr className="border-2 border-t border-[#dcdcdc]" />

            {isMenuSair ? (
              <li onClick={handleMenuClickDisconect}>
                <a className="flex items-center py-2 px-4 text-[#85480e] cursor-pointer">
                  <BiLogOut className="ml-5" size={20} />
                  <span className="ml-2">Sair</span>
                </a>
              </li>
            ) : null}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
