/**
 *  baseURL:
 *     baseURL: "https://ncnw6fzs82.execute-api.sa-east-1.amazonaws.com",
 *     baseURL: "http://localhost:8080",
 *
 */
import axios from "axios";

export function getAPIClient() {
  const api = axios.create({
    baseURL: "https://ncnw6fzs82.execute-api.sa-east-1.amazonaws.com"
  });

  api.interceptors.request.use((config) => {
    return config;
  });

  return api;
}

export const api = getAPIClient();
