import { api } from "../../services/api";

export async function fetchDescricaoFavoritos(excluir, propertyId, descricao) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@jarvis:token");

    api
      .post(
        "/dev/api/v1/cli/descricao/favorito",
        {
          excluir,
          idfavorito: propertyId.fav_id,
          descricao,
          token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
