import React from "react";
import { BiUser, BiExit, BiHelpCircle } from "react-icons/bi";

export default function Example({ titulo, subtitulo }) {
  const handleMenuClickDisconect = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-[13px] font-bold leading-7 text-slate-500 sm:truncate sm:text-3xl sm:tracking-tight">
          {titulo}
        </h2>

        <h2 className="text-[15px] text-slate-500 ">{subtitulo}</h2>
      </div>
      <div className="mt-5 flex lg:ml-4 lg:mt-0">
        <span className="hidden sm:block">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <BiHelpCircle
              className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            Suporte
          </button>
        </span>

        <span className="ml-3 hidden sm:block">
          <a href="/perfil">
            <button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              <BiUser
                className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Meu Perfil
            </button>
          </a>
        </span>

        <span className="sm:ml-3">
          <button
            onClick={handleMenuClickDisconect}
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <BiExit className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Sair
          </button>
        </span>
      </div>
    </div>
  );
}
