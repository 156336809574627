import React from "react";

const Modal = ({ children, onClose }) => {
  return (
    <>
      {/* Fundo escuro que cobre toda a tela */}
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        {/* Modal ocupa toda a tela com rolagem interna */}
        <div className="bg-white rounded-lg shadow-lg w-full h-full overflow-auto">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl p-4" // Aumente o tamanho do botão aqui
          >
            &times; {/* Símbolo de fechar */}
          </button>
          <div className="p-6 max-h-full overflow-auto">{children}</div>
        </div>
      </div>
      {/* Click no fundo escuro para fechar o modal */}
      <div
        className="fixed inset-0 z-40 bg-black opacity-50"
        onClick={onClose}
      />
    </>
  );
};

export default Modal;
