import React, { useState, useEffect } from "react";
import slid1 from "../../assets/slids/image-1@2x.png";
import { FaDownload } from "react-icons/fa";
import { formatToBRL } from "brazilian-values";
import "./index.css"; // Importando o arquivo CSS
import html2pdf from "html2pdf.js";

const CardImage = ({ property, tipovisao, acessoucel }) => {
  const [tipocoluna2, setTipocoluna2] = useState(2); // Estado para tipocoluna2
  const [isMobile, setIsMobile] = useState(false); // Estado para rastrear se é móvel ou não

  const isMobileDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    );
  };

  useEffect(() => {
    // Verifica se é um dispositivo móvel e atualiza o estado
    const mobile = isMobileDevice();
    setIsMobile(mobile);
    setTipocoluna2(mobile ? 1 : 2); // Define tipocoluna2 como 1 se for móvel, caso contrário, 0
  }, []);

  if (!property) {
    property = [];
  }

  if (!tipovisao) {
    tipovisao = "desktop";
  }

  if (!acessoucel) {
    acessoucel = "mobile";
  }

  let tipocoluna = 2;
  if (tipovisao === "mobile") {
    tipocoluna = 1;
  }

  if (acessoucel === 1) {
    tipocoluna = 1;
  }

  const modalidades = [
    "",
    "1º Leilão SFI",
    "2º Leilão SFI",
    "Leilão",
    "Licitação Aberta",
    "Venda Direta Online",
    "Venda Online"
  ];

  const formapagamento = ["À vista", "Financiamento"];

  const imoveisDesocupados = ["Sim", "Não"];

  // Estados para armazenar o valor de cada input
  const [itbi, setItbi] = useState(0);
  const [registro, setRegistro] = useState("");
  const [condominio, setCondominio] = useState("");
  const [luzMensal, setLuzMensal] = useState("");
  const [valorParcela, setValorParcela] = useState("");
  const [taxasCaixa, setTaxasCaixa] = useState("");
  const [entrada, setEntrada] = useState("");
  const [reforma, setReforma] = useState("");
  const [outrosCustos, setOutrosCustos] = useState("");
  const [advogado, setAdvogado] = useState("");
  const [corretagem, setCorretagem] = useState("");
  const [ganhoCapital, setGanhoCapital] = useState("");
  const [prazoVenda, setPrazoVenda] = useState("");
  const [valorVenda, setValorVenda] = useState("");
  const [tImagem, setImagem] = useState("");

  // Exemplo de uso no componente
  const [lanceMinimo, setLanceMinimo] = React.useState("R$ 0,00");

  // Função para formatar valor em reais
  const formatCurrency = (value) => {
    return value
      .replace(/\D/g, "") // Remove tudo que não é dígito
      .replace(/(\d)(\d{8})$/, "$1.$2") // Adiciona ponto antes dos últimos 8 dígitos
      .replace(/(\d)(\d{5})$/, "$1.$2") // Adiciona ponto antes dos últimos 5 dígitos
      .replace(/(\d)(\d{2})$/, "$1,$2") // Adiciona vírgula antes dos últimos 2 dígitos
      .replace(/^(?!0,)\D+/, ""); // Remove caracteres não numéricos no início
  };

  // Função para manipular alterações e formatar valor
  const handleChange = (setter) => (e) => {
    const formattedValue = formatCurrency(e.target.value);
    setter(`R$ ${formattedValue}`);
  };

  const [selecionadomodalidade, setSelecionadomodalidade] = useState("");

  const handleChangeModalidade = (event) => {
    setSelecionadomodalidade(event.target.value);
  };

  const [selecioImoveisOcupado, setSelecioImoveisOcupado] = useState("");

  const handleChangeImoveisOcupados = (event) => {
    setSelecioImoveisOcupado(event.target.value);
  };

  //Como pretende pagar func
  const [selecionadocomopagar, setSelecionadocomopagar] = useState("");

  const handleChangeComoPagar = (event) => {
    setSelecionadocomopagar(event.target.value);
  };

  const funFinanciado = () => {
    let valorInteiro = parseInt(
      lanceMinimo
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(".", "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    if (selecionadocomopagar === "Financiamento") {
      return valorInteiro - funcEntrada();
    } else {
      return 0;
    }
  };

  const funcEntrada = () => {
    let valorInteiro = parseInt(
      lanceMinimo
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(".", "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    if (selecionadocomopagar === "Financiamento") {
      let entradaDecimal = parseFloat(entrada) / 100;

      return valorInteiro * entradaDecimal;
    } else {
      return 0;
    }
  };

  const funcLeiloeiro = () => {
    const valorInteiro = parseInt(
      lanceMinimo
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(".", "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    if (selecionadomodalidade === "Licitação aberta") {
      return valorInteiro * 0.05;
    } else if (selecionadomodalidade === "1º Leilão SFI") {
      return valorInteiro * 0.05;
    } else if (selecionadomodalidade === "2º Leilão SFI") {
      return valorInteiro * 0.05;
    } else if (selecionadomodalidade === "Leilão") {
      return valorInteiro * 0.05;
    } else {
      return 0;
    }
  };

  const funcITBI = () => {
    const valorInteiro = parseInt(
      lanceMinimo
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(".", "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    if (valorInteiro) {
      return (itbi / 100) * valorInteiro;
    } else {
      return 0;
    }
  };

  const funcValorParcela = () => {
    const valorInteiro = parseInt(
      valorParcela
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(".", "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    if (selecionadocomopagar === "Financiamento") {
      return valorInteiro;
    } else {
      return 0;
    }
  };

  const funcTaxasCaixa = () => {
    const valorInteiro = parseInt(
      taxasCaixa
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(".", "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    if (selecionadocomopagar === "Financiamento") {
      return valorInteiro;
    } else {
      return 0;
    }
  };

  const funcAdvogado = () => {
    if (selecioImoveisOcupado === "Não") {
      return advogado;
    } else {
      return 0;
    }
  };

  const funcCustJudi = () => {
    const valorInteiro = parseInt(
      lanceMinimo
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(".", "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    if (selecioImoveisOcupado === "Não") {
      return (2.5 / 100) * valorInteiro;
    } else {
      return 0;
    }
  };

  const funcCorretagem = () => {
    const valorInteiro = parseInt(
      valorVenda
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(".", "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    if (valorInteiro) {
      return valorInteiro * (corretagem / 100);
    } else {
      return 0;
    }
  };

  const funcGanhoCapital = () => {
    let valorInteiro = parseFloat(
      valorVenda
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(/\./g, "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    // Converter finalgangoa (que é uma string) para número e porcentagem
    let finalgangoaNumerico =
      parseFloat(
        ganhoCapital.replace("%", "") // Remove o símbolo de porcentagem, se houver
      ) / 100; // Converte a porcentagem para decimal

    if (!isNaN(valorInteiro) && !isNaN(finalgangoaNumerico)) {
      // Retorna o cálculo do lucro final
      return funcLucroBruto() * finalgangoaNumerico;
    } else {
      return 0; // Retorna 0 caso os valores não sejam válidos
    }
  };

  const funcLucroBruto = () => {
    let valorInteiro = parseFloat(
      valorVenda
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(/\./g, "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    // Verifica se 'valorInteiro' é um número válido
    if (!isNaN(valorInteiro) && valorInteiro > 0) {
      // Retorna o cálculo subtraindo as funções de custos

      return (
        valorInteiro -
        funFinanciado() -
        funcCorretagem() -
        funcInvestimentoTotal()
      );
    }

    return 0; // Retorna 0 caso o valor não seja válido
  };

  const funcLucroLiquido = () => {
    let valorInteiro = parseFloat(
      valorVenda
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(/\./g, "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    // Verifica se 'valorInteiro' é um número válido
    if (!isNaN(valorInteiro) && valorInteiro > 0) {
      // Retorna o cálculo subtraindo as funções de custos

      return funcLucroBruto() - funcGanhoCapital();
    }

    return 0; // Retorna 0 caso o valor não seja válido
  };

  const funcRetornoRoi = () => {
    let valorInteiro = parseFloat(
      valorVenda
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(/\./g, "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    let lucroLiquido = funcLucroLiquido(); // Supomos que essa função já retorna um número
    let investimentoTotal = funcInvestimentoTotal(); // Supomos que essa função já retorna um número

    // Verifica se 'valorInteiro' é um número válido
    if (!isNaN(valorInteiro) && valorInteiro > 0) {
      // Retorna o cálculo subtraindo as funções de custos

      return (lucroLiquido / investimentoTotal) * 100; // Multiplica por 100 para converter em porcentagem
    }

    return 0; // Retorna 0 caso o valor não seja válido
  };

  const funcParcela = () => {
    if (selecionadocomopagar === "Financiamento") {
      return prazoVenda * funcValorParcela();
    } else {
      return 0;
    }
  };

  const funcCondominio = () => {
    const valorInteiro = parseFloat(
      condominio
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(".", "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    if (valorInteiro) {
      return valorInteiro * prazoVenda;
    } else {
      return 0;
    }
  };

  const funcLuz = () => {
    const valorInteiro = parseFloat(
      luzMensal
        .replace("R$", "") // Remove o símbolo de moeda
        .replace(".", "") // Remove os pontos de separação de milhar
        .replace(",", ".") // Substitui a vírgula decimal por um ponto
    );

    if (valorInteiro) {
      return prazoVenda * valorInteiro;
    } else {
      return 0;
    }
  };

  // Função para converter valores em formato de moeda para números
  const parseCurrency = (value) => {
    if (typeof value === "string") {
      // Remove o símbolo de moeda, pontos de milhar e substitui a vírgula por ponto decimal
      return parseFloat(
        value.replace("R$", "").replace(/\./g, "").replace(",", ".")
      );
    }
    // Se já é um número, retorna-o diretamente
    return parseFloat(value);
  };

  const funcInvestimentoTotal = () => {
    // Parsing específico para o valorRegistro e outros valores
    const valores = {
      valorRegistro: registro ? parseCurrency(registro) : 0,
      valorLanceMinimo: lanceMinimo ? parseCurrency(lanceMinimo) : 0,
      valorOutrosCustos: outrosCustos ? parseCurrency(outrosCustos) : 0, // Assumir 0 se outrosCustos não for fornecido
      valorReforma: reforma ? parseCurrency(reforma) : 0,
      parseAdvogado: funcAdvogado() ? parseCurrency(funcAdvogado()) : 0,
      parseLeiloeiro: funcLeiloeiro() ? parseCurrency(funcLeiloeiro()) : 0,
      parseEntrada: funcEntrada() ? parseCurrency(funcEntrada()) : 0,
      parseCustJudi: funcCustJudi() ? parseCurrency(funcCustJudi()) : 0,
      parseTaxasCaixa: funcTaxasCaixa() ? parseCurrency(funcTaxasCaixa()) : 0,
      parseITBI: funcITBI() ? parseCurrency(funcITBI()) : 0,
      parseLuz: funcLuz() ? parseCurrency(funcLuz()) : 0,
      parseCondominio: funcCondominio() ? parseCurrency(funcCondominio()) : 0,
      parseParcela: funcParcela() ? parseCurrency(funcParcela()) : 0
    };

    // Condicional de acordo com a seleção
    if (selecionadocomopagar === "Financiamento") {
      return (
        valores.parseEntrada +
        valores.parseLeiloeiro +
        valores.parseITBI +
        valores.valorRegistro +
        valores.parseTaxasCaixa +
        valores.valorReforma +
        valores.valorOutrosCustos + // A soma será feita normalmente, usando 0 se não houver outrosCustos
        valores.parseAdvogado +
        valores.parseCustJudi +
        valores.parseParcela +
        valores.parseCondominio +
        valores.parseLuz
      );
    } else {
      return (
        valores.valorLanceMinimo +
        valores.parseLeiloeiro +
        valores.parseITBI +
        valores.valorRegistro +
        funcValorParcela() +
        valores.parseTaxasCaixa +
        valores.valorReforma +
        valores.valorOutrosCustos + // A soma será feita normalmente, usando 0 se não houver outrosCustos
        valores.parseAdvogado +
        valores.parseCustJudi +
        funcParcela() +
        valores.parseCondominio +
        valores.parseLuz
      );
    }
  };

  const handleCapture = () => {
    const element = document.getElementById("capture");

    const options = {
      filename: "captura.pdf",
      image: { type: "jpeg", quality: 1 }, // Qualidade JPEG
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }
    };

    // Gerar o PDF diretamente
    html2pdf()
      .from(element)
      .set(options)
      .save()
      .then(() => {
        console.log("PDF gerado com sucesso!");
      })
      .catch((error) => {
        console.error("Erro ao gerar PDF:", error);
      });
  };

  useEffect(() => {
    if (property) {
      setSelecionadomodalidade(property.bas_modalidade_venda);

      let conversMinin = JSON.stringify(property.bas_valor_minimo_venda);
      setLanceMinimo(formatToBRL(conversMinin));

      if (property.bas_image) {
        setImagem(property.bas_image);
      } else {
        setImagem(slid1);
      }
    } else {
      //   setSelecionadomodalidade(property.bas_modalidade_venda);
    }
  }, []);

  return (
    <div
      id="capture"
      className="max-w-5xl mx-2 mt-5 w-full mb-5 p-6 bg-[#fafafa] border-2 border-[#979797] border-opacity-30 shadow-lg rounded-lg"
    >
      <div className="hidden-on-print">
        <div className="grid grid-cols-1 mb-2 bg-slate-200 rounded-lg p-2">
          <p className="text-slate-500 font-semibold w-full text-[15px]">
            Calculadora de viabilidade
          </p>
        </div>
      </div>

      {property && (
        <div className="grid grid-cols-1 gap-4">
          <div className="flex flex-wrap items-start relative mt-2 bg-gray-200 rounded-lg ml-0 mr-2 w-full">
            <div className="relative overflow-hidden w-full md:w-1/3 border-blue-900 shadow-sm rounded-md mx-auto">
              <div className="w-full h-48">
                <img
                  alt="Imagem do imóvel"
                  draggable="false"
                  src={tImagem || slid1}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="flex flex-col items-start relative p-2 flex-grow">
              <div className="relative self-stretch mb-1">
                <div className="text-gray-700 text-base font-bold">
                  {property.bas_title || ""}
                </div>
              </div>
              <div className="relative self-stretch mb-1">
                <div className="text-gray-700 text-sm">Bairro:</div>
              </div>
              <div className="relative self-stretch mb-1">
                <div className="text-gray-700 text-sm">
                  {property.bas_address || ""}
                </div>
              </div>
              <div className="relative self-stretch mb-1">
                <div className="text-gray-700 text-sm">
                  Avaliado em:{" "}
                  {!isNaN(parseFloat(property.bas_valor_avaliacao))
                    ? formatToBRL(property.bas_valor_avaliacao)
                    : "Não disponível"}
                </div>
              </div>
              <div className="relative self-stretch mb-1">
                <div className="text-gray-700 text-sm">
                  Lance mínimo:{" "}
                  {!isNaN(parseFloat(property.bas_valor_minimo_venda))
                    ? formatToBRL(property.bas_valor_minimo_venda)
                    : "Não disponível"}{" "}
                  {(property.bas_desconto / 100).toFixed(2).replace(".", ",")}
                </div>
              </div>
              <div className="relative self-stretch">
                <div className="text-gray-700 text-sm">
                  Modalidade:{" "}
                  {property.bas_modalidade_venda || "Sem Modalidade"}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`grid grid-cols-${tipocoluna2} gap-4 mt-5`}>
        <div className="grid grid-cols-1 p-1 mb-2 rounded-md">
          <div className="flex flex-col items-start relative mt-2 bg-gray-200 rounded-lg w-full">
            {/* MODALIDADE */}
            <div className="relative self-stretch mb-4">
              <div className="font-bold text-gray-700 text-[13px] bg-gray-300 p-3 rounded-lg w-full text-center">
                MODALIDADE
              </div>
              {/* Conteúdo centralizado horizontalmente */}
              <div className="flex items-center w-full max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Modalidade
                  </div>
                  <select
                    value={selecionadomodalidade}
                    onChange={handleChangeModalidade}
                    className="p-2 border ml-5 border-gray-300 rounded w-2/3"
                  >
                    {modalidades.map((opcao, index) => (
                      <option key={index} value={opcao}>
                        {opcao}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* FORMA DE PAGAMENTO E PRAZO */}
            <div className="relative self-stretch mb-4">
              <div className="font-bold text-gray-700 text-[13px] bg-gray-300 p-3 rounded-lg w-full text-center">
                FORMA DE PAGAMENTO E PRAZO
              </div>
              {/* Conteúdo centralizado horizontalmente */}
              <div className="flex items-center w-full mt-5  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Como pretende pagar
                  </div>
                  <select
                    value={selecionadocomopagar}
                    onChange={handleChangeComoPagar}
                    className="p-2 border ml-5 border-gray-300 rounded w-2/3"
                  >
                    {formapagamento.map((opcao, index) => (
                      <option key={index} value={opcao}>
                        {opcao}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Lance mínimo */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Lance mínimo
                  </div>
                  <input
                    type="text"
                    placeholder="R$ 0,00"
                    value={
                      !isNaN(parseFloat(lanceMinimo.replace(/[^0-9.-]+/g, "")))
                        ? lanceMinimo
                        : "R$ 0,00"
                    }
                    onChange={handleChange(setLanceMinimo)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
            </div>

            {/* DESPESAS DE CARTÓRIO */}
            <div className="relative self-stretch mb-4">
              <div className="font-bold text-gray-700 text-[13px] bg-gray-300 p-3 rounded-lg w-full text-center">
                DESPESAS DE CARTÓRIO
              </div>
              {/* Conteúdo centralizado horizontalmente */}
              <div className="flex items-center w-full mt-5  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    ITBI
                  </div>
                  <input
                    type="number"
                    placeholder="%"
                    value={itbi}
                    onChange={(e) => setItbi(e.target.value)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
              {/* Registro */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Registro
                  </div>
                  <input
                    type="text"
                    placeholder="R$ 0,00"
                    value={registro}
                    onChange={handleChange(setRegistro)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
            </div>

            {/* DESPESAS MENSAIS 	 */}
            <div className="relative self-stretch mb-4">
              <div className="font-bold text-gray-700 text-[13px] bg-gray-300 p-3 rounded-lg w-full text-center">
                DESPESAS MENSAIS
              </div>

              {/* Condomínio */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Condomínio
                  </div>
                  <input
                    type="text"
                    placeholder="R$ 0,00"
                    value={condominio}
                    onChange={handleChange(setCondominio)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
              {/* Luz Mensal */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Luz Mensal
                  </div>
                  <input
                    type="text"
                    placeholder="R$ 0,00"
                    value={luzMensal}
                    onChange={handleChange(setLuzMensal)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
            </div>

            {/* DESPESAS DO FINANCIAMENTO	 */}
            <div className="relative self-stretch mb-4">
              <div className="font-bold text-gray-700 text-[13px] bg-gray-300 p-3 rounded-lg w-full text-center">
                DESPESAS DO FINANCIAMENTO
              </div>
              {/* Conteúdo centralizado horizontalmente */}
              <div className="flex items-center w-full mt-5  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Valor da Parcela
                  </div>
                  <input
                    type="text"
                    placeholder="R$ 0,00"
                    value={valorParcela}
                    onChange={handleChange(setValorParcela)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
              {/* Taxas Caixa */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Taxas Caixa
                  </div>
                  <input
                    type="text"
                    placeholder="R$ 0,00"
                    value={taxasCaixa}
                    onChange={handleChange(setTaxasCaixa)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
              {/* Entrada */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Entrada (%)
                  </div>
                  <input
                    type="number"
                    placeholder="%"
                    value={entrada}
                    onChange={(e) => setEntrada(e.target.value)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
            </div>

            {/* DEMAIS DESPESAS		 */}
            <div className="relative self-stretch mb-4">
              <div className="font-bold text-gray-700 text-[13px] bg-gray-300 p-3 rounded-lg w-full text-center">
                DEMAIS DESPESAS
              </div>
              {/* Conteúdo centralizado horizontalmente */}
              <div className="flex items-center w-full mt-5  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Reforma
                  </div>
                  <input
                    type="text"
                    placeholder="R$ 0,00"
                    value={reforma}
                    onChange={handleChange(setReforma)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
              {/* Taxas Caixa */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Outros custos
                  </div>
                  <input
                    type="text"
                    placeholder="R$ 0,00"
                    value={outrosCustos}
                    onChange={handleChange(setOutrosCustos)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
              {/* Entrada */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Imóves está desocupado?
                  </div>
                  <select
                    value={selecioImoveisOcupado}
                    onChange={handleChangeImoveisOcupados}
                    className="p-2 border ml-5 border-gray-300 rounded w-2/3"
                  >
                    {imoveisDesocupados.map((opcao, index) => (
                      <option key={index} value={opcao}>
                        {opcao}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* Reforma */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Advogado
                  </div>
                  <input
                    type="text"
                    placeholder="R$ 0,00"
                    value={advogado}
                    onChange={handleChange(setAdvogado)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>

              {/* Corretagem  */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Corretagem
                  </div>
                  <input
                    type="number"
                    placeholder="%"
                    value={corretagem}
                    onChange={(e) => setCorretagem(e.target.value)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
              {/* Ganho de capital (Imposto) */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Ganho de capital (Imposto)
                  </div>
                  <input
                    type="number"
                    placeholder="%"
                    value={ganhoCapital}
                    onChange={(e) => setGanhoCapital(e.target.value)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
            </div>

            {/* EXPECTATIVAS		 */}
            <div className="relative self-stretch mb-4">
              <div className="font-bold text-gray-700 text-[13px] bg-gray-300 p-3 rounded-lg w-full text-center">
                EXPECTATIVAS
              </div>
              {/* Conteúdo centralizado horizontalmente */}
              <div className="flex items-center w-full mt-5  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Prazo de venda (meses)
                  </div>
                  <input
                    type="number"
                    placeholder=""
                    value={prazoVenda}
                    onChange={(e) => setPrazoVenda(e.target.value)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
              {/* Valor de Venda */}
              <div className="flex items-center w-full  max-w-md mt-2">
                <div className="flex items-center w-full">
                  <div className="font-bold text-[13px] ml-5 text-gray-700 w-1/3">
                    Valor de Venda
                  </div>
                  <input
                    type="text"
                    placeholder="R$ 0,00"
                    value={valorVenda}
                    onChange={handleChange(setValorVenda)}
                    className="p-2 ml-5 border border-gray-300 rounded w-2/3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 p-1 mb-2 rounded-md mt-2 colored-element">
          <div className="flex flex-col items-start bg-[#FFF9DA] border border-[#A9ADB9] shadow-lg rounded-lg p-6">
            <div className="mb-6 self-center">
              <div className="text-[#575757] text-2xl font-bold leading-6">
                Resultados
              </div>
            </div>

            {/* MODALIDADE */}
            <div className="mb-4 w-full">
              <div className="text-[#575757] font-semibold text-lg border-b border-[#A9ADB9] pb-2">
                MODALIDADE
              </div>
              <table className="w-full mt-2">
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Tipo do imóvel:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {selecionadomodalidade}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* FORMA DE PAGAMENTO E PRAZO */}
            <div className="mb-4 w-full">
              <div className="text-[#575757] font-semibold text-lg border-b border-[#A9ADB9] pb-2">
                FORMA DE PAGAMENTO E PRAZO
              </div>
              <table className="w-full mt-2">
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Lance mínimo:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {!isNaN(parseFloat(lanceMinimo.replace(/[^0-9.-]+/g, "")))
                        ? lanceMinimo
                        : "R$ 0,00"}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Valor financiado:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funFinanciado() || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Entrada:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcEntrada() || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Leiloeiro:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcLeiloeiro() || 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* DESPESAS DE CARTÓRIO */}
            <div className="mb-4 w-full">
              <div className="text-[#575757] font-semibold text-lg border-b border-[#A9ADB9] pb-2">
                DESPESAS DE CARTÓRIO
              </div>
              <table className="w-full mt-2">
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      ITBI:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcITBI() || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Registro:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {registro}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* DESPESAS MENSAIS */}
            <div className="mb-4 w-full">
              <div className="text-[#575757] font-semibold text-lg border-b border-[#A9ADB9] pb-2">
                DESPESAS MENSAIS
              </div>
              <table className="w-full mt-2">
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Condomínio:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {condominio}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Luz mensal:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {luzMensal}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* DESPESAS DO FINANCIAMENTO */}
            <div className="mb-4 w-full">
              <div className="text-[#575757] font-semibold text-lg border-b border-[#A9ADB9] pb-2">
                DESPESAS DO FINANCIAMENTO
              </div>
              <table className="w-full mt-2">
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Valor Parcela:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcValorParcela() || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Taxas da Caixa:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcTaxasCaixa() || 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* DEMAIS DESPESAS */}
            <div className="mb-4 w-full">
              <div className="text-[#575757] font-semibold text-lg border-b border-[#A9ADB9] pb-2">
                DEMAIS DESPESAS
              </div>
              <table className="w-full mt-2">
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Reforma:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {reforma}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Outros custos:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {outrosCustos}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Imóvel está desocupado?:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {selecioImoveisOcupado}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Advogado:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {funcAdvogado()}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Custas Judiciais:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcCustJudi() || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Corretagem:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcCorretagem() || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Ganho de capital (Imposto):
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcGanhoCapital() || 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* TOTAIS */}
            <div className="mb-4 w-full">
              <div className="text-[#575757] font-semibold text-lg border-b border-[#A9ADB9] pb-2">
                TOTAIS
              </div>
              <table className="w-full mt-2">
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Parcela:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcParcela() || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Condomínio:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcCondominio() || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Luz:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcLuz() || 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* INVESTIMENTO TOTAL */}
            <div className="mb-4 w-full">
              <div className="text-[#575757] font-semibold text-lg border-b border-[#A9ADB9] pb-2">
                INVESTIMENTO TOTAL
              </div>
              <table className="w-full mt-2">
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Investimento Total:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcInvestimentoTotal() || 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* EXPECTATIVAS */}
            <div className="mb-4 w-full">
              <div className="text-[#575757] font-semibold text-lg border-b border-[#A9ADB9] pb-2">
                EXPECTATIVAS
              </div>
              <table className="w-full mt-2">
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Prazo (meses):
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {prazoVenda}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Valor de venda:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {valorVenda}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* RETORNO */}
            <div className="mb-4 w-full">
              <div className="text-[#575757] w-full font-semibold text-lg border-b border-[#A9ADB9] pb-2">
                RETORNO
              </div>
              <table className="w-full mt-2">
                <tbody>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Lucro Bruto:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcLucroBruto() || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Lucro Líquido:
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {formatToBRL(funcLucroLiquido() || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      Retorno (ROI):
                    </td>
                    <td className="py-2 px-4 border-b border-[#A9ADB9]">
                      {!isNaN(funcRetornoRoi()) && funcRetornoRoi() !== 0
                        ? `${funcRetornoRoi().toFixed(2).replace(".", ",")}%`
                        : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <button
            onClick={handleCapture}
            className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] mt-4 py-2 rounded-2xl text-slate-100 mb-2 flex items-center justify-center"
          >
            <FaDownload className="mr-2" /> Download PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardImage;
