import { api } from "../../services/api";

export async function fetchSalvarFavorito(idimovel) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@jarvis:token");
    api
      .post(
        "/dev/api/v1/cli/salvar/favorito",
        {
          idimovel,
          token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
