import { api } from "../../services/api";

export async function fetchPessquisar(
  estado,
  lanceMin,
  lanceMax,
  valorMin,
  valorMax,
  modalidade,
  desconto,
  tipoImovel,
  banco,
  escolhacidade,
  aceitaFinanciamento,
  page
) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@jarvis:token");

    api
      .post(
        "/dev/api/v1/cli/search",
        {
          estado,
          lanceMin,
          lanceMax,
          valorMin,
          valorMax,
          modalidade,
          desconto,
          tipoImovel,
          banco,
          escolhacidade,
          aceitaFinanciamento,
          token,
          page, // Incluindo o número da página no corpo da requisição
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
