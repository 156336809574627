import { useState, createContext } from "react";
import { api } from "../services/api";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchMonitor } from "../integration/monitor/fetchMonitor";

export const AuthContext = createContext({});

export function AuthContextProvider(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  async function signAdmin(user) {
    setLoading(true);
    setError("");

    // Função para disparar a API
    const updateUserStatus = async (token2) => {
      let status = "Online";
      try {
        await fetchMonitor(status, token2);
      } catch (erro) {
        console.log("Erro no monitoramento:", erro);
      }
    };

    await api
      .post("/dev/sessions", user)
      .then(({ data }) => {
        const token = data.token;
        try {
          // Decodifica o token para verificar a expiração
          const decodedToken = jwt(token);
          const currentTime = Math.floor(Date.now() / 1000); // Hora atual em segundos

          if (
            decodedToken.cli_expiracao &&
            decodedToken.cli_expiracao > currentTime
          ) {
            // Calcula os dias restantes antes da expiração
            const timeRemaining = decodedToken.cli_expiracao - currentTime;
            const daysRemaining = Math.floor(timeRemaining / (60 * 60 * 24)); // Converte segundos em dias

            // Exibe toast de sucesso com os dias restantes
            toast.success(
              `Login bem-sucedido! Dias restantes: ${daysRemaining} dias.`
            );

            updateUserStatus(token); // Chama a API com status "Online"

            // Token é válido, salva no localStorage
            localStorage.setItem("@jarvis:token", token);

            // Redireciona para o dashboard
            navigate("/dashboard");
          } else {
            // Token expirado
            //  setError("Token expirado. Faça login novamente.");
            toast.error("Sua assinatura expirou.");
          }
        } catch (error) {
          // Erro ao decodificar o token
          setError("Erro ao validar o token. Faça login novamente.");
          toast.error("Erro ao validar o token. Faça login novamente.");
        }
      })
      .catch(({ response }) => {
        setError(response.data);
        toast.error(`Erro: ${response.data}`);
      })
      .finally(() => setLoading(false));
  }

  function logout() {
    navigate("/login");
  }

  return (
    <AuthContext.Provider value={{ user, loading, error, signAdmin, logout }}>
      {props.children}
      {/* Container para exibir os toasts */}
      <ToastContainer />
    </AuthContext.Provider>
  );
}
