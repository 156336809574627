import { api } from "../../services/api";
import jwt from "jwt-decode";

export async function fetchSalvarFIltro(filtro) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("@jarvis:token");

    let isValid = false;
    let iduser = null;

    if (token) {
      const unscrypted = jwt(token);
      isValid = !!unscrypted.iat && !!unscrypted.cli_id;

      iduser = unscrypted.cli_id;
    }

    api
      .post(
        "/dev/api/v1/cli/save/filter",
        {
          idcliente: iduser,
          nomefiltro: filtro.nomefiltro,
          idsimoveis: filtro.selectIdImoveis,
          token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
}
