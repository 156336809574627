import React, { useState, useRef, useEffect } from "react";
import Logo from "../../assets/logo/logo.png";

const MatrixEffect = () => {
  const canvasRef = useRef(null);
  const codeRainIntervalRef = useRef(null);
  const ctxRef = useRef(null);
  const codeColors = [
    "#dc9f22",
    "#525962",
    "#dc9f22",
    "#525962",
    "#dc9f22",
    "#525962",
    "#dc9f22",
    "#525962",
  ];
  const phrases = ["JARVIS"];
  const [useRandomChars, setUseRandomChars] = useState(true);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    ctxRef.current = context;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  useEffect(() => {
    const context = ctxRef.current;

    const createCodeRain = () => {
      const columns = Math.ceil(window.innerWidth / 20);
      const drops = [];
      for (let i = 0; i < columns; i++) {
        drops[i] = 1;
      }

      const drawCodeRain = () => {
        context.fillStyle = "rgba(0, 0, 0, 0.04)";
        context.fillRect(0, 0, window.innerWidth, window.innerHeight);

        context.font = "15px Arial";

        for (let i = 0; i < drops.length; i++) {
          let code;
          if (useRandomChars) {
            code = String.fromCharCode(Math.floor(Math.random() * 128));
          } else {
            const phraseIndex = Math.floor(Math.random() * phrases.length);
            const phrase = phrases[phraseIndex];
            code = phrase.charAt(i % phrase.length);
          }
          const color =
            codeColors[Math.floor(Math.random() * codeColors.length)];

          context.fillStyle = color;
          context.fillText(code, i * 20, drops[i] * 30);

          if (drops[i] * 20 > window.innerHeight && Math.random() > 0.975) {
            drops[i] = 0;
          }

          drops[i]++;
        }
      };

      codeRainIntervalRef.current = setInterval(drawCodeRain, 30);
    };

    createCodeRain();

    return () => {
      clearInterval(codeRainIntervalRef.current);
    };
  }, [useRandomChars]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100vh",
        background: "#ffffff",
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
      <img
        src={Logo}
        alt="Logo"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
          width: "350px",
          height: "auto",
        }}
      />
    </div>
  );
};

export default MatrixEffect;
