import React, { useState, useEffect } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { fetchPessquisar } from "../../integration/pesquisar/fetchPessquisar";
import { fetchCidades } from "../../integration/cidades/fetchCidades";
import { fetchSalvarFIltro } from "../../integration/salvarfiltros/fetchSalvarFIltro";

import { formatToBRL } from "brazilian-values";

import ComponentCardImovel from "../../components/card_imovel_buscaavancado/index";

const estadosBrasil = [
  "Acre",
  "Alagoas",
  "Amapá",
  "Amazonas",
  "Bahia",
  "Ceará",
  "Distrito Federal",
  "Espírito Santo",
  "Goiás",
  "Maranhão",
  "Mato Grosso",
  "Mato Grosso do Sul",
  "Minas Gerais",
  "Pará",
  "Paraíba",
  "Paraná",
  "Pernambuco",
  "Piauí",
  "Rio de Janeiro",
  "Rio Grande do Norte",
  "Rio Grande do Sul",
  "Rondônia",
  "Roraima",
  "Santa Catarina",
  "São Paulo",
  "Sergipe",
  "Tocantins"
];

const tiposImoveis = [
  "Apartamento",
  "Area",
  "Box de garagem",
  "Casa",
  "Chacara_Sitio",
  "Chácara",
  "Comercial",
  "Fazenda",
  "Galpão",
  "Gleba",
  "Imóvel rural",
  "Loja",
  "Lote",
  "Outros",
  "Prédios",
  "Sala",
  "Sala Comercial",
  "Sobrado",
  "Terreno"
];

const modalidadevalue = [
  "1º Leilão",
  "2º Leilão",
  "Leilão",
  "Licitação Aberta",
  "Venda Direta Online",
  "Venda Online"
];

const FiltroPesquisa = ({ versao }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Total de páginas
  const [totalencontrado, setTotalencontrado] = useState(false);

  // Função para mudar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  let tipovisao = 2;

  if (versao === "mobile") {
    tipovisao = 1;
  }

  const [filtro, setFiltro] = useState({
    estado: [],
    lanceMin: 0,
    lanceMax: 0,
    valorMin: 0,
    valorMax: 0,
    desconto: 0,
    tipoImovel: [],
    modalidade: [],
    banco: "caixa",
    dataEncerramento: "",
    aceitaFinanciamento: false,
    pagamentoVista: false,
    imoveisDesocupados: false,
    imoveisNovos: false,
    imoveisEmDisputa: false,
    naoMostrarVendidos: false
  });

  const [cardmovelon, setCardmovelon] = useState(false);

  const [mostrarListaEstados, setMostrarListaEstados] = useState(false);
  const [mostrarListaTiposImovel, setMostrarListaTiposImovel] = useState(false);

  const [mostrarCidades, setMostrarCidades] = useState(false);
  const [cidades, setCidades] = useState([]);
  const [cidadesSelecionadas, setCidadesSelecionadas] = useState([]);
  const [todasCidadesSelecionadas, setTodasCidadesSelecionadas] =
    useState(false);

  const [dadosSearch, setDadosSearch] = useState([]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      if (name === "estado") {
        if (value === "todos") {
          setFiltro({
            ...filtro,
            estado: checked ? estadosBrasil : []
          });
        } else {
          const updatedEstados = checked
            ? [...filtro.estado, value]
            : filtro.estado.filter((estado) => estado !== value);

          setFiltro({
            ...filtro,
            estado: updatedEstados
          });
        }
      } else if (name === "tipoImovel") {
        const updatedTipos = checked
          ? [...filtro.tipoImovel, value]
          : filtro.tipoImovel.filter((tipo) => tipo !== value);

        setFiltro({
          ...filtro,
          tipoImovel: updatedTipos
        });
      } else if (name === "modalidade") {
        // Novo bloco para modalidade
        const updatedModalidades = checked
          ? [...filtro.modalidade, value]
          : filtro.modalidade.filter((modalidade) => modalidade !== value);

        setFiltro({
          ...filtro,
          modalidade: updatedModalidades
        });
      } else {
        setFiltro({
          ...filtro,
          [name]: checked
        });
      }
    } else {
      setFiltro({
        ...filtro,
        [name]: value
      });
    }
  };

  const handleCurrencyChange = (e) => {
    const { name, value } = e.target;

    // Remove todos os caracteres que não são dígitos
    const numericValue = value.replace(/\D/g, "");

    // Verifica se o valor numérico é válido e diferente de vazio
    const validNumericValue = numericValue ? Number(numericValue) : 0;

    // Converte o valor numérico para BRL
    const formattedValue = numericValue
      ? formatToBRL(validNumericValue / 100)
      : "";

    setFiltro({
      ...filtro,
      [name]: formattedValue
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aqui você pode adicionar lógica para aplicar os filtros
  };

  const toggleTodosEstados = () => {
    const todosSelecionados = filtro.estado.length === estadosBrasil.length;
    setFiltro({
      ...filtro,
      estado: todosSelecionados ? [] : estadosBrasil
    });
  };

  const toggleListaEstados = () => {
    setMostrarListaEstados(!mostrarListaEstados);
  };

  const toggleListaTiposImovel = () => {
    setMostrarListaTiposImovel(!mostrarListaTiposImovel);
  };

  const selecionarTodosTiposImovel = () => {
    setFiltro({
      ...filtro,
      tipoImovel: tiposImoveis
    });
  };

  const [msgErro, setMsgErro] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false); // Estado para controle de carregamento

  const [selectIdImoveis, setSelectIdImoveis] = useState([]); // Estado para controle de carregamento

  const funcDisparoAPI = async (page = 1) => {
    if (typeof page !== "number" || page <= 0) {
      return;
    }
    setLoading(true); // Começar o carregamento
    setDadosSearch([]);
    // Define filtros padrão se não estiverem selecionados
    const estado = filtro.estado.length > 0 ? filtro.estado : estadosBrasil;
    const tipoImovel =
      filtro.tipoImovel.length > 0 ? filtro.tipoImovel : tiposImoveis;
    const banco = filtro.banco.length > 0 ? filtro.banco : "caixa";
    const escolhacidade =
      cidadesSelecionadas.length > 0 ? cidadesSelecionadas[0] : "";

    try {
      const resp = await fetchPessquisar(
        estado,
        filtro.lanceMin,
        filtro.lanceMax,
        filtro.valorMin,
        filtro.valorMax,
        filtro.modalidade,
        filtro.desconto,
        tipoImovel,
        banco,
        escolhacidade,
        filtro.aceitaFinanciamento,
        page // Adiciona a página como parâmetro
      );

      setMsgErro("");
      setCardmovelon(true);

      setTotalencontrado(resp.totalItems);

      // Atualiza os dados com base na página
      // Aqui estamos verificando se a resposta contém dados
      if (resp && resp.data) {
        if (page === 1) {
          setDadosSearch(resp.data); // Atualiza os dados na primeira página
        } else {
          setDadosSearch((prevDados) => [...prevDados, ...resp.data]); // Anexa novos dados
        }
      }

      // Atualiza a página atual e total de páginas
      setCurrentPage(resp.currentPage);
      setTotalPages(resp.totalPages);

      // Atualiza os IDs dos imóveis
      const numerosImoveis = resp.data.map((item) => item.bas_numero_imovel);
      setSelectIdImoveis(numerosImoveis);
    } catch (erro) {
      const errorTratado =
        erro.info || "Erro na busca. Por favor, tente novamente.";
      setCardmovelon(false);
      setMsgErro(errorTratado);
      setSuccess("");
    } finally {
      setLoading(false); // Finalizar o carregamento
    }
  };

  // Função para carregar mais dados ao clicar no botão ou ao rolar a página

  const handleSearch = (event) => {
    event.preventDefault(); // Impede o comportamento padrão do formulário
    setCurrentPage(1); // Reinicia a página ao fazer uma nova busca
    funcDisparoAPI(1); // Chama a API para a primeira página
  };

  const handleNextPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber); // Atualiza a página atual com o número selecionado
      funcDisparoAPI(pageNumber); // Chama a função para carregar os dados da nova página
    }
  };

  const voltarpagina = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage); // Atualiza a página atual
      funcDisparoAPI(prevPage); // Chama a função para carregar os dados da nova página
    }
  };

  // Função para mudar de página
  const irpagina = (currentPage) => {
    if (currentPage > 1) {
      const prevPage = currentPage;
      setCurrentPage(prevPage); // Atualiza a página atual
      funcDisparoAPI(prevPage); // Chama a função para carregar os dados da nova página
    }
  };

  /**
   * cidades
   */

  // Atualiza as cidades quando apenas um estado é selecionado
  useEffect(() => {
    if (mostrarListaEstados && filtro.estado.length === 1) {
      funcCidades();
    } else {
      setCidadesSelecionadas([]);
      setCidades([]);
      setMostrarCidades(false);
    }
  }, [filtro.estado]);

  // Função para lidar com a seleção de cidades
  const handleCidadeChange = (event) => {
    const { options } = event.target;
    const selectedCidades = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedCidades.push(options[i].value);
      }
    }
    setCidadesSelecionadas(selectedCidades);

    setTodasCidadesSelecionadas(selectedCidades.length === cidades.length);
    setMostrarListaEstados(false);
  };

  const handleSelecionarTodas = () => {
    if (todasCidadesSelecionadas) {
      setCidadesSelecionadas([]);
      setTodasCidadesSelecionadas(false);
    } else {
      setMostrarListaEstados(false);
      setCidadesSelecionadas(cidades);
      setTodasCidadesSelecionadas(true);
    }
  };

  const funcCidades = async () => {
    setLoading(true);

    try {
      const resp = await fetchCidades(filtro.estado);

      setMsgErro("");
      // Remover duplicatas
      const cidadesUnicas = [...new Set(resp.cidades)];
      setCidades(cidadesUnicas); // Atualiza o estado com as cidades únicas
      setMostrarCidades(true); // Define que as cidades devem ser mostradas
    } catch (erro) {
      setMsgErro("Erro na busca2!");
      setSuccess("");
      setMostrarCidades(false); // Desativar a exibição das cidades em caso de erro
    } finally {
      setLoading(false); // Finalizar o carregamento
    }
  };

  const funcLimparFiltro = async () => {
    window.location.reload(); // Recarrega a página atual
  };

  const [inputSalvar, setInputSalvar] = useState(false); // Estado para controle de carregamento

  const funcSalvarFiltro = async () => {
    setInputSalvar(!inputSalvar);
    setSuccess("");
    setMsgErro("");
    setNomeFiltro("");
  };

  const [exibirForm, setExibirForm] = useState(false);

  const [nomefiltro, setNomeFiltro] = useState("");

  const handleSalvarFiltro = async () => {
    if (nomefiltro === "") {
      alert("Por favor, insira o nome do filtro");
      return;
    }

    const filtro = {
      nomefiltro,
      selectIdImoveis
    };

    // Faça a requisição para salvar o filtro
    setLoading(true);

    try {
      const resp = await fetchSalvarFIltro(filtro);
      setSuccess(resp.message);
      setMsgErro("");
      setInputSalvar(!inputSalvar);
    } catch (erro) {
      setMsgErro("Erro em salvar filtro");
      setSuccess("");
      setMostrarCidades(false); // Desativar a exibição das cidades em caso de erro
    } finally {
      setLoading(false); // Finalizar o carregamento
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center  items-center w-full bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]">
          <AiOutlineLoading className="animate-spin text-[#dda219] text-4xl" />
        </div>
      ) : (
        <>
          {!cardmovelon && (
            <form
              onSubmit={handleSubmit}
              className="p-4 mt-5 bg-gray-100 rounded shadow-lg mx-auto"
            >
              {success && !msgErro && (
                <div className="text-center my-5 bg-green-400 border border-slate-100 rounded p-2">
                  <div className="text-[13px] text-slate-100">{success}</div>
                </div>
              )}

              {msgErro && !success && (
                <div className="text-center my-5 bg-red-500 border border-slate-100 rounded p-2">
                  <div className="text-[13px] text-slate-100">{msgErro}</div>
                </div>
              )}

              <div className="mb-4">
                <div className="text-center my-5 bg-slate-200 border border-slate-400 rounded p-3">
                  <div className="text-[13px] text-slate-800">
                    Se nenhum <strong>"Estado"</strong> ou{" "}
                    <strong>"Tipo do Imóvel"</strong> ou for selecionado,
                    imóveis de todas as regiões e tipos do Brasil serão
                    exibidos.
                  </div>
                </div>

                <label className="block mb-1">
                  Estados Selecionados: {filtro.estado.length}{" "}
                </label>

                <div className="flex items-center mb-2 flex-wrap rounded-md">
                  <button
                    type="button"
                    onClick={toggleListaEstados}
                    className="bg-slate-300 text-slate-500 px-3 py-1 rounded shadow-sm"
                  >
                    Listar Estados
                  </button>
                  {mostrarListaEstados && (
                    <>
                      <button
                        type="button"
                        onClick={toggleTodosEstados}
                        className="bg-slate-300 text-slate-500 ml-2 px-3 py-1 rounded shadow-sm"
                      >
                        {filtro.estado.length === estadosBrasil.length
                          ? "Desmarcar Todos"
                          : "Selecionar Todos"}
                      </button>
                      <div className="mt-2 w-full">
                        {estadosBrasil.map((estado) => (
                          <div
                            key={estado}
                            className="flex items-center mr-4 mb-2 rounded-md"
                          >
                            <input
                              type="checkbox"
                              name="estado"
                              value={estado}
                              checked={filtro.estado.includes(estado)}
                              onChange={handleInputChange}
                              className="mr-2"
                            />
                            <span>{estado}</span>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                {/* Seleção de cidades */}
                {mostrarCidades && (
                  <div className="mb-4">
                    <label className="block text-gray-700 font-bold mb-2">
                      Cidades:
                    </label>
                    <div className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        checked={todasCidadesSelecionadas}
                        onChange={handleSelecionarTodas}
                        className="mr-2"
                      />
                      <span>Selecionar todas as cidades</span>
                    </div>
                    <select
                      multiple
                      onChange={handleCidadeChange}
                      className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-200"
                    >
                      {cidades.map((cidade, index) => (
                        <option key={index} value={cidade}>
                          {cidade}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Exibir cidades selecionadas */}
                <div>
                  <h3 className="text-lg font-bold mb-2">
                    Cidade selecionadas:
                  </h3>
                  <ul className="list-disc pl-6">
                    {cidadesSelecionadas.map((cidade, index) => (
                      <li key={index}>{cidade}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="flex w-full">
                <div className="mb-4 flex-1">
                  <label className="block mb-1">Lance Mínimo</label>

                  <input
                    type="text"
                    id="lanceMin"
                    name="lanceMin"
                    placeholder="0"
                    value={filtro.lanceMin}
                    onChange={handleCurrencyChange}
                    className="w-full p-2 border rounded text-slate-500"
                  />
                </div>

                <div className="mb-4 ml-2 flex-1">
                  <label className="block mb-1">Lance Máximo</label>

                  <input
                    type="text"
                    id="lanceMax"
                    name="lanceMax"
                    placeholder="0"
                    value={filtro.lanceMax}
                    onChange={handleCurrencyChange}
                    className="w-full p-2 border rounded text-slate-500"
                  />
                </div>
              </div>

              <div className="flex w-full">
                <div className="mb-4 flex-1">
                  <label className="block mb-1">
                    Valor de Avaliação Mínimo
                  </label>

                  <input
                    type="text"
                    id="valorMin"
                    name="valorMin"
                    placeholder="0"
                    value={filtro.valorMin}
                    onChange={handleCurrencyChange}
                    className="w-full p-2 border rounded text-slate-500"
                  />
                </div>

                <div className="mb-4 ml-2 flex-1">
                  <label className="block mb-1">
                    Valor de Avaliação Máximo
                  </label>

                  <input
                    type="text"
                    id="valorMax"
                    name="valorMax"
                    placeholder="0"
                    value={filtro.valorMax}
                    onChange={handleCurrencyChange}
                    className="w-full p-2 border rounded text-slate-500"
                  />
                </div>
              </div>

              <div className="mb-4">
                <label className="block mb-1">Desconto</label>
                <input
                  type="range"
                  name="desconto"
                  min="0"
                  max="100"
                  value={filtro.desconto}
                  onChange={handleInputChange}
                  className="w-full "
                />
                <div className="text-[20px] text-slate-500 font-bold">
                  {filtro.desconto}%
                </div>
              </div>

              <div className="mb-4">
                <label className="block mb-1">
                  Imóvel Selecionados: {filtro.tipoImovel.length}{" "}
                </label>
                <div className="flex items-center mb-2 flex-wrap rounded-md">
                  <button
                    type="button"
                    onClick={toggleListaTiposImovel}
                    className="bg-slate-300 text-slate-500  px-3 py-1 rounded shadow-sm"
                  >
                    {filtro.tipoImovel.length > 0
                      ? "Listar Imóveis"
                      : "Listar Imóveis"}
                  </button>
                  {mostrarListaTiposImovel && (
                    <>
                      <button
                        type="button"
                        onClick={selecionarTodosTiposImovel}
                        className="bg-slate-300 text-slate-500  ml-2  px-3 py-1 rounded shadow-sm"
                      >
                        Selecionar Todos
                      </button>
                      <div className="mt-2 w-full">
                        {tiposImoveis.map((tipo) => (
                          <div
                            key={tipo}
                            className="flex items-center mr-4 mb-2 rounded-md"
                          >
                            <input
                              type="checkbox"
                              name="tipoImovel"
                              value={tipo}
                              checked={filtro.tipoImovel.includes(tipo)}
                              onChange={handleInputChange}
                              className="mr-2"
                            />
                            <span>{tipo}</span>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="mb-4">
                <select
                  name="banco"
                  value={filtro.banco}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                >
                  <label className="block mb-1">Banco</label>

                  <option value="caixa">Caixa</option>
                </select>
              </div>

              <div className="my-10">
                {modalidadevalue.map((modalidade) => (
                  <label key={modalidade} className="flex items-center">
                    <input
                      type="checkbox"
                      name="modalidade"
                      value={modalidade}
                      checked={filtro.modalidade.includes(modalidade)}
                      onChange={handleInputChange}
                      className="mr-2"
                    />
                    {modalidade}
                  </label>
                ))}
              </div>

              {/* Checkbox Aceita Financiamento */}
              <div className="my-10">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="aceitaFinanciamento"
                    checked={filtro.aceitaFinanciamento}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Aceita Financiamento
                </label>
              </div>

              <button
                type="submit"
                onClick={handleSearch}
                className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] mt-4 py-2 rounded-2xl text-slate-100 mb-2 flex items-center justify-center"
              >
                Buscar imóveis
              </button>
            </form>
          )}

          {cardmovelon && (
            <>
              <div
                className={`grid grid-cols-2 p-2  gap-2 w-full   bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]`}
              >
                {/* Coluna do conteúdo */}

                <button
                  type="submit"
                  className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c]   py-2 rounded-2xl text-slate-100 mb-2 flex items-center justify-center"
                  onClick={() => {
                    funcSalvarFiltro();
                  }}
                >
                  Salvar Filtro
                </button>

                <button
                  type="submit"
                  className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#c1c1c1] to-[#c1c1c1]   py-2 rounded-2xl text-slate-600 mb-2 flex items-center justify-center"
                  onClick={() => {
                    funcLimparFiltro();
                  }}
                >
                  Limpar Filtro
                </button>

                {dadosSearch && (
                  <div className="grid grid-cols-1 p-1 mb-2 rounded-md bg-gray-200">
                    <p className="text-gray-600 font-semibold text-[15px]">
                      Página: {currentPage} / {totalPages}
                    </p>
                    <p className="text-gray-600 font-semibold text-[15px]">
                      Imóveis encontrados: {totalencontrado}
                    </p>
                  </div>
                )}
              </div>
            </>
          )}

          {success && !msgErro && (
            <div className="text-center my-5 bg-green-400 border border-slate-100 rounded p-2">
              <div className="text-[13px] text-slate-100">{success}</div>
            </div>
          )}

          {!exibirForm && cardmovelon && inputSalvar && (
            <div
              className={`grid grid-cols-1   p-2  gap-2 w-full   bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]`}
            >
              <label htmlFor="nomeFiltro" className="block text-gray-700">
                Nome do Filtro:
              </label>
              <input
                type="text"
                id="nomeFiltro"
                value={nomefiltro}
                onChange={(e) => setNomeFiltro(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-lg"
                placeholder="Digite o nome do filtro"
              />

              <button
                type="button"
                onClick={handleSalvarFiltro}
                className="mt-4 bg-green-400 text-white py-2 px-4 rounded-lg"
              >
                Confirmar e Salvar
              </button>
            </div>
          )}

          {dadosSearch.length > 0 && (
            <div
              className={`grid grid-cols-1 w-full bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]`}
            >
              <select
                value={currentPage}
                onChange={(e) => handleNextPage(Number(e.target.value))} // Passa o valor diretamente
                className="ml-2 text-sm text-gray-800 bg-gray-100 dark:bg-gray-600 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-800"
              >
                {Array.from({ length: totalPages }, (_, i) => (
                  <option key={i + 1} value={i + 1}>
                    Página {i + 1}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div
            className={`grid grid-cols-${tipovisao} w-full bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]`}
          >
            {cardmovelon && dadosSearch.length > 0 ? (
              dadosSearch.map((imovel, index) => (
                <div key={index} className="flex flex-col mb-4">
                  <div
                    className="flex flex-grow bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4] border-l-2 border-gray-200"
                    style={{ width: "100%" }}
                  >
                    <ComponentCardImovel dados={imovel} />
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500"></p>
            )}
          </div>

          {cardmovelon && dadosSearch.length > 0 && (
            <div className="flex justify-center mt-8 my-10">
              <div className="flex items-center justify-between w-full text-gray-600 dark:text-gray-400 bg-gray-100 rounded-lg dark:bg-gray-600 max-w-[128px] mx-2">
                {/* Botão Anterior */}
                <button
                  type="button"
                  onClick={() => voltarpagina(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="inline-flex items-center justify-center h-8 px-1 w-6 bg-gray-100 rounded-s-lg dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-800"
                >
                  <svg
                    className="w-2 h-2 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 1 1 5l4 4"
                    />
                  </svg>
                  <span className="sr-only">Página anterior</span>
                </button>

                {/* Exibe o número da página atual e o total de páginas */}
                <span className="flex-shrink-0 mx-1 text-sm font-medium space-x-0.5 rtl:space-x-reverse">
                  {currentPage} de {totalPages}
                </span>

                {/* Botão Próximo */}
                <button
                  type="button"
                  onClick={() => irpagina(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="inline-flex items-center justify-center h-8 px-1 w-6 bg-gray-100 rounded-e-lg dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-800"
                >
                  <svg
                    className="w-2 h-2 rtl:rotate-180"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <span className="sr-only">Próxima página</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default FiltroPesquisa;
