import Menu from "../../../components/menu";
import React, { useState, useEffect } from "react";
import MatrixComponent from "../../../components/matrix/Index";
import CardImoveil from "../../../components/card_imovel/index";

function MobileView() {
  const columnHeight = {
    col00: "5%",
    col0: "7%",
    col1: "100%",
    col2: "75%",
    col3: "75%",
    col4: "20%",
  };

  const columnWidth = {
    col0: "20%",
    col1: "100%",
    col2: "30%",
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="flex h-screen bg-[#262626] ">
      {/* Menu lateral */}
      <nav
        className={`bg-gradient-to-tr from-[#dcdcdc] to-[#e4e4e4] text-white py-4 w-full md:w-64 fixed inset-y-0 left-0 z-10 ${
          isMenuOpen ? "block" : "hidden"
        } md:block`}
      >
        <Menu />
      </nav>

      {/* Botão do Menu (aparece apenas em telas pequenas) */}
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="md:hidden fixed p-5 top-4 right-4   bg-[#dca118] text-white px-4 py-2 rounded-lg z-20"
      >
        {isMenuOpen ? "Fechar Menu" : "Abrir Menu"}
      </button>

      {/* Coluna do conteúdo */}
      <div
        className="w-full md:w-auto bg-gradient-to-tr from-[#dcdcdc] to-[#e4e4e4] border-l-2 border-gray-200"
        style={{ height: columnHeight.col1, width: columnWidth.col1 }}
      >
        <div className="grid grid-cols-1 p-5 gap-2 bg-gradient-to-tl  from-[#e4e4e4] to-[#ffffff]">
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-[20px] font-bold leading-7 text-slate-500 sm:truncate sm:text-3xl sm:tracking-tight">
                Página Inicial
              </h2>
            </div>
          </div>
        </div>

        <div className="  p-2 gap-1 w-full bg-gradient-to-tr from-[#dcdcdc] to-[#e4e4e4]">
          <CardImoveil tipovisao={"mobile"} />
        </div>
      </div>
    </div>
  );
}

function DesktopView() {
  const columnHeight = {
    col00: "5%",
    col0: "7%",
    col1: "100%",
    col2: "75%",
    col3: "75%",
    col4: "20%",
  };

  const columnWidth = {
    col0: "20%",
    col1: "100%",
    col2: "30%",
  };

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col h-screen">
      {!showContent && <MatrixComponent />}

      {showContent && (
        <div className="flex flex-grow">
          {/* Coluna do menu */}
          <div className="w-full md:w-auto bg-gradient-to-tl border-2 border-r-[#dcdcdc] from-[#e4e4e4] to-[#ffffff]">
            <Menu />
          </div>

          {/* Coluna do conteúdo */}
          <div
            className="w-full md:w-auto  bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4] border-l-2 border-gray-200"
            style={{ height: columnHeight.col1, width: columnWidth.col1 }}
          >
            <CardImoveil tipovisao={"desktop"} />
          </div>
        </div>
      )}
    </div>
  );
}

function Index() {
  return (
    <div>
      <div className="lg:hidden">
        <MobileView />
      </div>
      <div className="hidden lg:block">
        <DesktopView />
      </div>
    </div>
  );
}

export default Index;
