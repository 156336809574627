import { Navigate, Outlet } from "react-router-dom";
import jwt from "jwt-decode";
import { useEffect, useState } from "react";
import { fetchMonitor } from "../integration/monitor/fetchMonitor";

export default function ProtectedRoute({ redirectPath = "/" }) {
  const token = localStorage.getItem("@jarvis:token");
  const [isUserActive, setIsUserActive] = useState(true);

  let isValid = false;

  if (token) {
    const unscrypted = jwt(token);
    isValid =
      !!unscrypted.iat && !!unscrypted.cli_id && !!unscrypted.cli_expiracao;

    localStorage.setItem("@jarvis:nome", unscrypted.cli_nome);
    localStorage.setItem("@jarvis:telefone", unscrypted.cli_telefone);
    localStorage.setItem("@jarvis:email", unscrypted.cli_email);
    localStorage.setItem("@jarvis:localidade", unscrypted.cli_localidade);
  }

  // Função para disparar a API
  const updateUserStatus = async (status) => {
    try {
      await fetchMonitor(status);
    } catch (erro) {
      console.log("Erro no monitoramento:", erro);
    }
  };

  useEffect(() => {
    // Função que detecta se é um reload ou fechamento real
    const handleBeforeUnload = (event) => {
      if (!event?.currentTarget?.performance?.navigation?.type === 1) {
        // Se NÃO for um recarregamento da página (reload)
        updateUserStatus("Offline"); // Chama a API com status "Offline" ao sair
      }
    };

    // Detecta quando a aba perde ou ganha visibilidade
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        updateUserStatus("Offline");
      } else if (document.visibilityState === "visible") {
        setIsUserActive(true); // Marca o usuário como ativo
        updateUserStatus("Online");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // Remove os event listeners quando o componente desmonta
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  if (!token || !isValid) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
}
