import React, { useState } from "react";
import { useAuth } from "../../../hooks/Auth";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { FiUnlock } from "react-icons/fi";
import { BiLogoGmail, BiLockAlt } from "react-icons/bi";
import logo from "./../../../assets/logo/logo.png";

function Login() {
  const nomedosistema = "JARVIS - LEILÕES";
  const { signAdmin, error } = useAuth();
  const [user, setUser] = useState({
    email: "",
    senha: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [modoJarvis, setModoJarvis] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleWithSubmit = async (e) => {
    e.preventDefault(); // Previne o comportamento padrão do formulário

    if (user.email === "" || user.senha === "") {
      return;
    }

    await signAdmin(user);
  };

  const toggleModoJarvis = () => {
    setModoJarvis(!modoJarvis);
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-tr from-[#ffffff] to-[#DCDCDC] bg-cover bg-center relative">
      <div className="justify-center items-center self-center z-10 p-5 rounded-md">
        <form onSubmit={handleWithSubmit} className="w-full max-w-md">
          <h1 className="flex items-center justify-center mb-8">
            <img className="h-24" src={logo} alt="" />
          </h1>

          <div className="flex items-center bg-[#d7d7d7] border-2 none border-[#c7c7c7] py-2 px-3 rounded-2xl mb-4">
            <BiLogoGmail className="h-5 w-5 text-[#be850c]" />
            <input
              required
              className="pl-2 bg-transparent placeholder-slate-500 ml-2 w-full bg-[#535353] outline-none border-none rounded text-gray-500"
              onChange={handleChange}
              value={user.email}
              type="email"
              name="email"
              id="email"
              placeholder="email"
            />
          </div>

          <div className="flex items-center bg-[#d7d7d7] border-2 none border-[#c7c7c7] py-2 px-3 rounded-2xl mb-4">
            <BiLockAlt className="h-5 w-5 text-[#be850c]" />
            <input
              required
              className="pl-2 bg-transparent placeholder-slate-500 ml-2 w-full bg-[#535353] outline-none border-none rounded text-gray-500"
              placeholder="Senha"
              onChange={handleChange}
              value={user.senha}
              type={showPassword ? "text" : "password"}
              name="senha"
              id="senha"
            />
            {showPassword ? (
              <RiEyeOffFill
                className="h-5 w-5 text-[#be850c] ml-2 cursor-pointer"
                onClick={handleTogglePassword}
              />
            ) : (
              <RiEyeFill
                className="h-5 w-5 text-[#be850c] ml-2 cursor-pointer"
                onClick={handleTogglePassword}
              />
            )}
          </div>

          {error && (
            <div className="text-center my-5 bg-red-500 border border-slate-100 rounded p-2">
              <div className="text-[13px] text-slate-100">{error}</div>
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] mt-4 py-2 rounded-2xl text-slate-100 mb-2 flex items-center justify-center"
          >
            <FiUnlock className="mr-2 text-slate-100 font-bold" /> Acessar
          </button>

          <h1 className="text-[#656565] text-center text-[10px] mt-4">
            Copyright © 2024 {nomedosistema}
          </h1>
          <h1 className="text-[#656565] text-center text-[10px]">
            Todos os direitos reservados
          </h1>
        </form>
      </div>
    </div>
  );
}

export default Login;
